import request from '@/utils/request'
import requestFormData from '@/utils/requestFormData';

export function searchCompany(val) {//查找公司
  return request({
    url: '/admin/company/finaName?companyName=' + val,
    method: 'get'
  })
}

export function queryProjectList(param) {//查找项目
  return request({
    url: '/admin/project/paging-list',
    method: 'post',
    data: param
  })
}


export function queryProjectListNew(param) {//查找项目
  return request({
    url: '/admin/project/selectList',
    method: 'post',
    data: param
  })
}

export function addProject(param) {//添加项目
  return request({
    url: '/admin/project/add',
    method: 'post',
    data: param
  })
}
//导入项目 /admin/project/import
export function importProject(param) {//添加项目
  return requestFormData({
    url: '/admin/project/import',
    method: 'post',
    data: param
  })
}

export function editProject(param) {//编辑项目
  return request({
    url: '/admin/project/edit',
    method: 'post',
    data: param
  })
}
export function findProjectDetail(param) {//项目详情
  return request({
    url: '/admin/project/detail',
    method: 'post',
    data: {
      id: param.id,
    }
  })
}
export function findProjectAllDetail(param) {//项目详情_项目的所有的细节
  return request({
    url: '/admin/project/all-detail',
    method: 'post',
    data: param
  })
} 
export function deleteProjectById(id) {//删除项目
  return request({
    url: '/admin/project/delete',
    method: 'post',
    data: {
      id: id,
    }
  })
}

export function claimProjectById(id) {//认领项目
  return request({
    url: '/admin/project/claim',
    method: 'post',
    data: {
      id: id,
    }
  })
}
export function transferProject(parmas) {//项目转移
  return request({
    url: '/admin/project/transferProject',
    method: 'post',
    data: parmas
  })
}
export function assessmentProject(parmas) {//评估开始
  return request({
    url: '/admin/evaluation/recordStartTime',
    method: 'post',
    data: parmas
  })
}
//

export function assessmentHistory(parmas) {//条件查询项目流程评估记录组合数据列表
  return request({
    url: '/admin/evaluation/record/pj-workflow-list',
    method: 'post',
    data: parmas
  })
}
export function findQuestionList(parmas) {//题目列表
  return request({
    // url: '/admin/evaluationQuestion/findEvaluationQuestionList',
    url: '/admin/evaluation/question/paging-list-by-module',
    method: 'post',
    data: parmas
  })
}
export function submitQuestionSl(parmas) {//提交
  return request({
    url: '/admin/evaluationQuestion/submitEvaluationQuestion',
    method: 'post',
    data: parmas
  })
}
export function newSubmitQuestion(parmas) {//提交
  return request({
    url: '/admin/evaluation/record/submit',
    method: 'post',
    data: parmas
  })
}
export function evaluationRecord(parmas) {//评估记录
  return request({
    url: '/admin/evaluationDetail/findEvaluationDetailList',
    method: 'post',
    data: parmas
  })
}
export function projectTeam(parmas) {//项目组成员
  return request({
    url: '/admin/system/user/selectList',
    method: 'post',
    data: parmas
  })
}

export function findTypeProject(parmas) {//评估类型列表
  return request({
    url: '/admin/evaluationQuestion/findTypeByProject?projectId=' + parmas,
    method: 'get'
  })
}
export function selectTypeProject(parmas) {//条件查询流程选择列表
  return request({
    url: '/admin/workflow/selectList',
    method: 'post',
    data: parmas
  })
}
export function selectEvaluationTemplate(parmas) {//评估模板的下拉列表
  return request({
    url: '/admin/evaluation/template/selectList',
    method: 'post',
    data: parmas
  })
}

export function selectProjecStatus(parmas) {//评估模板的下拉列表
  return request({
    url: '/admin/project/status/business/selectList',
    method: 'post',
    data: parmas
  })
}


//新建/编辑项目流程
export function projecWorkflowAdd(parmas) {
  return request({
    url: '/admin/project/workflow/add',
    method: 'post',
    data: parmas
  })
}
export function projecWorkflowEdit(parmas) {
  return request({
    url: '/admin/project/workflow/edit',
    method: 'post',
    data: parmas
  })
}

//
export function projecTrackDetail(parmas) {//根据项目ID查询项目跟踪信息详情
  return request({
    url: '/admin/project/workflow/detail',
    method: 'post',
    data: parmas
  })
}
export function projecTrackAllDetail(parmas) {//根据项目ID查询项目跟踪信息详情
  return request({
    url: '/admin/project/workflow/all-detail',
    method: 'post',
    data: parmas
  })
}



//admin/project/workflow/paging-list
export function projecWorkflowList(parmas) {//业务流程列表
  return request({
    url: '/admin/project/workflow/paging-list',
    method: 'post',
    data: parmas
  })
}
//
export function projecWorkflowStatusEdit(parmas) {//业务流程状态修改
  return request({
    url: '/admin/project/workflow/status/edit',
    method: 'post',
    data: parmas
  })
}

export function deleteWorkflow(parmas) {
  return request({
    url: '/admin/project/workflow/delete',
    method: 'post',
    data: {
      id: parmas
    }
  })
}
// 生成、复制项目码
///admin/project/code/add
export function coypProjectCode(parmas) {
  return request({
    url: '/admin/project/code/add',
    method: 'post',
    data: {
      id: parmas
    }
  })
}

//
// 分页条件查询评估记录列表
export function getEvaluationRecordList(parmas) {
  return request({
    url: '/admin/evaluation/record/paging-list',
    method: 'post',
    data: parmas
  })
}

//
export function getEvaluationRecordTable(parmas) {
  return request({
    url: '/admin/evaluation/record/info-table',
    method: 'post',
    data: parmas
  })
}

export function getProjecWorkflowTree(parmas) {
  return request({
    url: '/admin/project/workflow/info-tree',
    method: 'post',
    data: parmas
  })
}

// 单次评估记录详情
export function getEvaluationRecordDetail(parmas) {
  return request({
    url: '/admin/evaluation/record/all-detail',
    method: 'post',
    data: parmas
  })
}

// 条件查询任务的文件列表
export function getTaskFileList(parmas) {
  return request({
    url: '/admin/task/file/list',
    method: 'post',
    data: parmas
  })
}

export function getDingTalkFileList(parmas) {
  return request({
    url: '/admin/ding-talk/drive/project/files',
    method: 'post',
    data: parmas
  })
}
export function getDingTalkFileDownloadInfo(parmas) {
  return request({
    url: '/admin/ding-talk/drive/project/file/download-info',
    method: 'post',
    data: parmas
  })
}


export function dingTalkFilePreview(parmas) {
  return request({
    url: '/admin/ding-talk/drive/project/file/preview?fileId=' + parmas.fileId + '&fileName=' + parmas.fileName + '&spaceType=' + (parmas.spaceType || 0)  + '&Authorization=' + window.localStorage.getItem("tokenId"),
    method: 'get'
  })
}

export function projectAssignUserList(workflowId) { //项目组成员负责人下拉列表
  return request({
    url: '/admin/project/workflow/userSelectList?workflowId=' + workflowId,
    method: 'get'
  })
}

export function findProjectBdList(params) { //查询项目BD
  return request({
    url: '/admin/project/find-project-bd-list',
    method: 'post',
    data: params
  })
}


export function getProjectTemplate() { //查询项目BD
  return request({
    url: '/system/setting/get?key=hermes_config_public_project_import_setting',
    method: 'get', 
  })
}

//followProject
export function followProject(params) { //关注
  return request({
    url: '/admin/favorite/add',
    method: 'post',
    data: params
  })
}
export function unFollowProject(params) { //关注
  return request({
    url: '/admin/favorite/delete',
    method: 'post',
    data: params
  })
}